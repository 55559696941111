<template>
    <el-dialog :visible.sync="dialogVisible" title="编辑轮播图" width="30%" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
            <el-form-item label="图片" prop="image_url">
                <img :src="$store.getters.getPicBaseUrl + ruleForm.image_url" class="banners" />
            </el-form-item>
            <el-form-item label="标题" prop="name">
                <el-input type="text" v-model.trim="ruleForm.name" maxlength="18" show-word-limit placeholder="输入标题(可不填)"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="content">
                <el-input type="text" v-model.trim="ruleForm.content" placeholder="请输入描述"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input type="number" v-model.trim="ruleForm.sort"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
                <el-switch v-model="ruleForm.status" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit('ruleForm')" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "editDialog",
        data(){
            return{
                action:'/api/carousel/test',
                dialogVisible:false,    //显示本组件
                fullscreenLoading: false,//loading框
                ruleForm: {},
                rules: {
                    remark: [
                        { required: false},
                        { min: 0, max: 18, message: '长度在 0 到 18 个字符', trigger: 'blur' }
                    ],
                    image_url:[
                        { required: true,message:'图片未获取到',trigger:'blur'},
                    ],
                    sort:[
                        { required: true,message:'请输入排序数字',trigger:'blur'},
                    ],
                },
            }
        },
        methods:{
            //打开本组件
            open(row){
                this.ruleForm = JSON.parse(JSON.stringify(row));
                this.dialogVisible = true;
            },
            //点击提交
            async submit(formName){
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try{
                            const {data} = await this.$axios({
                                method:'POST',
                                url:'/api/carousel/update',
                                data:{
                                    id:this.ruleForm.id,
                                    sort:this.ruleForm.sort,
                                    status: this.ruleForm.status,
                                    image_url: this.ruleForm.image_url,
                                    content:this.ruleForm.content,
                                    name:this.ruleForm.name,
                                }
                            });
                            this.fullscreenLoading = false;
                            if(data.code){
                                this.$message({
                                    message: data.msg,
                                    type: 'success',
                                });
                                this.$emit('success');
                                this.dialogVisible = false;
                            }else{
                                this.$message.error(data.msg);
                            }
                        }catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .banners {
        width: 100%;
        height: auto;
        display: block;
    }
</style>
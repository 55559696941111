<template>
    <div class="navs">
        <div class="container">
            <div class="handle-box">
                <el-button type="primary" icon="el-icon-circle-plus-outline" class="handle-del mr10" @click="add">添加轮播图</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" :header-cell-style="{backgroundColor:'#f5f7fa'}">
                <el-table-column align="center" prop="image_url" label="图片地址" width="200">
                    <template slot-scope="scope">
                        <el-image style="width: 100%;" :src="$store.getters.getPicBaseUrl + scope.row.image_url" fit="contain"
                                  :preview-src-list="srcList"
                        />
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="name" label="标题" width="300"></el-table-column>
                <el-table-column align="center" prop="content" label="描述"></el-table-column>
                <el-table-column align="center" prop="sort" label="排序" width="100"></el-table-column>
                <el-table-column align="center" prop="status" label="状态" width="100">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status==1" type="success" effect="dark">启用</el-tag>
                        <el-tag v-else type="danger" effect="dark">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-edit" type="success" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" :total="total" @current-change="getList"></el-pagination>
            </div>
        </div>

        <Dialog ref="dialog" @success="getList" />
        <editDialog ref="editDialog" @success="getList" />
    </div>
</template>

<script>
    import Dialog from '@/components/Banners/dialog'
    import editDialog from '@/components/Banners/editDialog'
    export default {
        name: "Banners",
        components:{
            Dialog,
            editDialog,
        },
        data(){
            return{
                tableData:[],    //数据列表
                loading:false,   //loading框
                page:1,         //当前页
                size:10,        //每页条数
                total:0,        //总条数
                srcList:[],       //图片放大图数组
            }
        },
        created(){
            this.getList();
        },
        methods:{
            //获取列表数据
            async getList(page=1){
                try{
                    const {data} = await this.$axios(`/api/carousel/showall/${page}/${this.size}`);
                    this.loading = false;
                    if(data.code){
                        this.tableData = data.data;
                        this.total = data.count_num;
                        this.srcList = [];
                        this.tableData.forEach(item=>{
                            this.srcList.push(this.$store.getters.getPicBaseUrl + item.image_url);
                        });
                    }
                }catch (e) {
                    //
                }
            },
            //添加菜单
            add(){
                this.$refs.dialog.open();
            },
            //修改菜单信息
            handleEdit(row){
                this.$refs.editDialog.open(row);
            },
            //修改菜单状态
            changeStatus(){},
        },
    }
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }
</style>
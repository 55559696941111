<template>
    <el-dialog :visible.sync="dialogVisible" title="添加轮播图" width="600px" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="200px">
            <el-form-item label="选择图片(支持png、jpg格式图片。建议分辨率2560*710,大小不超过500k)" prop="image_url">
                <el-upload
                        class="banners-uploader"
                        :with-credentials="true"
                        :action="action"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="banners">
                    <i v-else class="el-icon-plus banners-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="标题" prop="name">
                <el-input type="text" v-model.trim="ruleForm.name" maxlength="18" show-word-limit placeholder="输入标题(可不填)"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="content">
                <el-input type="text" v-model.trim="ruleForm.content" maxlength="30" show-word-limit placeholder="输入描述(可不填)"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submit('ruleForm')" v-loading.fullscreen.lock="fullscreenLoading">确 定</el-button>
                </span>
        </template>
    </el-dialog>
</template>

<script>
    export default {
        name: "Dialog",
        data(){
            return{
                action:'/api/carousel/test',      //图片上传地址
                imageUrl: '',
                dialogVisible:false,    //显示本组件
                fullscreenLoading: false,//loading框
                ruleForm: {
                    name:'',           //标题
                    content:'',         //简介
                    image_url: '',      //图片名称
                },
                rules: {
                    name: [
                        { required: false},
                        { min: 0, max: 18, message: '长度在 0 到 18 个字符', trigger: 'blur' }
                    ],
                    content: [
                        { required: false},
                        { min: 0, max: 30, message: '长度在 0 到 30 个字符', trigger: 'blur' }
                    ],
                    image_url:[
                        { required: true,message:'请选择一张图片',trigger:'blur'},
                    ],
                },
            }
        },
        methods:{
            //打开本组件
            open(){
                this.dialogVisible = true;
            },
            //点击提交
            async submit(formName){
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try{
                            const {data} = await this.$axios({
                                method:'POST',
                                url:'/api/carousel/getdata',
                                data:{
                                    name: this.ruleForm.name,
                                    content: this.ruleForm.content,
                                    image_url:this.ruleForm.image_url,
                                }
                            });
                            this.fullscreenLoading = false;
                            if(data.code){
                                this.$message({
                                    message: data.msg,
                                    type: 'success',
                                });
                                this.$emit('success');
                                this.dialogVisible = false;
                            }else{
                                this.$message.error(data.msg);
                            }
                        }catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //上传成功执行
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                this.ruleForm.image_url = res.file_name;
            },
            //上传前校验
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt500K = file.size / 1024 < 500;

                if (!isJPG && !isPNG) {
                    this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
                }
                if (!isLt500K) {
                    this.$message.error('上传图片大小不能超过 500 Kb!');
                }
                return (isJPG||isPNG) && isLt500K;
            }
        },
        watch:{
            dialogVisible(flag){
                if(!flag){
                    this.$refs['ruleForm'].resetFields();
                    this.imageUrl = '';
                }
            },
        },
    }
</script>

<style>
    .banners-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        height: auto;
    }
    .banners-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .banners-uploader .el-upload--text{
        width: auto;
    }
    .banners-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .banners {
        width: 100%;
        height: auto;
        display: block;
    }
</style>